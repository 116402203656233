import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AR_EXPORTS, HtmlExportService, PdfExportService, TabularDataExportService, ViewerComponent } from '@mescius/activereportsjs-angular';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
  providers: [
    {
      provide: AR_EXPORTS,
      useClass: PdfExportService,
      multi: true,
    },
    {
      provide: AR_EXPORTS,
      useClass: HtmlExportService,
      multi: true,
    },
    {
      provide: AR_EXPORTS,
      useClass: TabularDataExportService,
      multi: true,
    },
  ],
})
export class ReportViewerComponent implements OnInit {
  @HostListener('window:resize', ['$event'])

  //#region Arrays and Objects
  windowWidth: any;
  public report: any;
  title: any = '';
  // formData: any[] = [];
  reportName: string = '';
  dataSetType: string = 'single';
  availableExports = ["pdf", "tabular-data"];
  @ViewChild(ViewerComponent, { static: false }) reportViewer!: ViewerComponent;
  //#endregion

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ReportViewerComponent>) {
    this.data = data.data;
    // this.formData = data.formData;
    this.title = data.title;
    this.reportName = data.reportName;
    this.dataSetType = data.dataSetType;
  }
  public onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }
  async ngOnInit(): Promise<void> {
    if (this.dataSetType === 'single') {
      await this.setReportDataSet();
    } else {
      await this.setMultipleReportDataSet();
    }
  }

  async setReportDataSet() {
    const reportResponse = await fetch(`assets/reports/${this.reportName}.rdlx-json`);
    const report = await reportResponse.json();

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(this.data);

    report.DataSets[0].fields = this.data;
    report.DataSets[0].Query.CommandText = 'jpath=$.*';
    // this.reportViewer.exportsSettings = {
    //   pdf: {
    //     title: this.title,
    //     author: "Automated Trackers",
    //     subject: this.title,
    //     keywords: "reporting, sample",
    //     userPassword: "",
    //     ownerPassword: "",
    //     printing: "none",
    //     copying: false,
    //     modifying: false,
    //     annotating: false,
    //     contentAccessibility: false,
    //     documentAssembly: false,
    //     pdfVersion: "1.7",
    //     autoPrint: false,
    //     filename: "Report.pdf",
    //   }
    // };
    this.reportViewer.availableExports = ["pdf"];
    this.reportViewer.open(report);
  }
  async setMultipleReportDataSet() {
    const reportResponse = await fetch(`assets/reports/${this.reportName}.rdlx-json`);
    const report = await reportResponse.json();

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(this.data);

    report.DataSets[0].fields = this.data;
    report.DataSets[0].Query.CommandText = 'jpath=$.*';

    report.DataSources[1].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(this.data[0].form);

    report.DataSets[1].fields = this.data[0].form;
    report.DataSets[1].Query.CommandText = 'jpath=$.*';

    this.reportViewer.open(report);
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
