import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectCityById' })
export class SelectCityById implements PipeTransform {
    transform(value: any[], cty_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.ass_childfk === cty_pk;
            }).map(o => o.cty_name);
        } else {
            return 'No data found';
        }
    }
}
