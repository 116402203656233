import { Component, Inject, Injectable, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { LoadingService } from 'src/app/core/services/loader.service';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PickerInteractionMode } from 'igniteui-angular';
import { startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  @ViewChild('appointmentForm', { static: true }) public appointmentForm!: NgForm;
  public mode: PickerInteractionMode = PickerInteractionMode.Dialog;
  public format = 'hh:mm tt';
  cardHeader: string = '';
  eventType: string = '';
  isModify: any = false;
  types: any[] = [
    {
      display: 'Appointment'
    },
    {
      display: 'Meeting'
    }
  ]
  selectedTypes: any = 1;
  app_startTime: any = '';
  app_endTime: any = '';
  appObj: any = {
    app_pk: -1,
    app_fk: -1,
    app_subject: '',
    app_location: '',
    app_startdatetime: new Date(),
    app_enddatetime: new Date(),
    app_alldayevent: false,
    app_remider: false,
    app_reminderinterval: 15,
    app_reminderunit: 'Minutes',
    app_barcolor: '#769EFF',
    app_description: '',
    app_table: 'House',
    app_email: '',
    app_ismeeting: false,
    app_createdby: '1',
    app_createddate: new Date(),
    app_updatedby: null,
    app_updateddate: null
  };
  constructor(private dialog: MatDialog,
    private loader: LoadingService,
    private appointmentService: AppointmentService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AppointmentComponent>) {
    this.cardHeader = data.cardHeader;
    this.appObj = data.data;
    this.eventType = data.type;
    this.isModify = data.isModify;
    this.app_startTime = JSON.parse(JSON.stringify(data.data.app_startdatetime));
    this.app_endTime = JSON.parse(JSON.stringify(data.data.app_enddatetime));
  }

  save(): void {
    if (this.appObj.app_subject !== '' && this.appObj.app_subject.length > 0) {
      if (this.isModify) {
        this.updateAppointment();
      }
      else {
        this.addAppointment();
      }
    }
  }
  addAppointment() {
    this.loader.show();
    this.appointmentService.create(this.appObj).subscribe(res => {
      this.loader.hide();
      this.dialogRef.close(res[0]);
    }, error => {
      this.loader.hide();
    });
  }
  updateAppointment() {
    let startTime;
    let endTime;
    if (isNaN(new Date(this.app_startTime).getHours())) {
      startTime = this.app_startTime;
    } else {
      startTime = new Date(this.app_startTime).getHours() + ':' + new Date(this.app_startTime).getMinutes();
    }
    if (isNaN(new Date(this.app_endTime).getHours())) {
      endTime = this.app_endTime;
    } else {
      endTime = new Date(this.app_endTime).getHours() + ':' + new Date(this.app_endTime).getMinutes();
    }

    this.loader.show();
    this.appObj.app_startdatetime = new Date(new Date(this.appObj.app_startdatetime).toLocaleDateString() + ' ' + startTime).toLocaleString();
    this.appObj.app_enddatetime = new Date(new Date(this.appObj.app_enddatetime).toLocaleDateString() + ' ' + endTime).toLocaleString();
    this.appObj.app_updatedby = '1';
    this.appObj.app_updateddate = new Date();
    this.appointmentService.update(this.appObj.app_pk, this.appObj).subscribe(res => {
      this.loader.hide();
      this.dialogRef.close(res[0]);
    }, error => {
      this.loader.hide();
    });
  }
  closeDialog(): void {
    if (this.appointmentForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        disableClose: true,
        data: { message: 'Do you wish to save changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.save();
        } else {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }
  onResize(event: any) { }
}
