<div class="admin-top-content">
  <a href="/"><img class="com-logo" src='{{ "assets/icons/SAMLogo.png" }}' alt="Automated Trackers"></a>
  <span class="header-com-name">{{loggedInUser}}</span>
  <!-- <span>Automated Trackers</span> -->
</div>
<div class="content-wrap" igxLayout>
  <div>
    <igx-nav-drawer class="drawer-wrapper" #drawer [isOpen]="navCollapsed" [enableGestures]="true"
      [disableAnimation]="true" [pinThreshold]="1024">
      <ng-template igxDrawer>
        <div class="collapse-btn-wrapper">
          <button igxIconButton="flat" *ngIf="drawer.isOpen === true" igxToggleAction="navigation"
            (click)="drawer.toggle()">
            <igx-icon family="material" class="collapse-btn-icon">arrow_back</igx-icon>
          </button>
          <button igxIconButton="flat" *ngIf="drawer.isOpen === false" igxToggleAction="navigation"
            (click)="drawer.toggle()">
            <igx-icon family="material" class="collapse-btn-icon">arrow_forward</igx-icon>
          </button>
        </div>
        <div>
          <igx-tree class="tree-content" #tree>
            <igx-tree-node [data]="route.title" *ngFor="let route of navigations">
              <a igxTreeNodeLink (click)="logOut()" *ngIf="route.id === 'logOut'" style="height: 100%;
              width: 100%;
              display: inline-block;">
                <span *ngIf="route.iconType==='image'" class="pcoded-micon">
                  <img class="png-icon" src='{{ "assets/icons/" + route.icon }}'></span>
                <span *ngIf="route.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="route.icon"
                    class="side-menu-icon"></i></span>
                {{ route.title }}
              </a>
              <a igxTreeNodeLink [routerLink]="route.url" (click)="navigate(route)" *ngIf="route.id !== 'logOut'" style="height: 100%;
              width: 100%;
              display: inline-block;">
                <span *ngIf="route.iconType==='image'" class="pcoded-micon">
                  <img class="png-icon" src='{{ "assets/icons/" + route.icon }}'>
                </span>
                <span *ngIf="route.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="route.icon"
                    class="side-menu-icon"></i></span>
                {{ route.title }}
              </a>
              <igx-tree-node [data]="child.title" *ngFor="let child of route.children">
                <a igxTreeNodeLink [routerLink]="child.url" (click)="openPopup(child)" *ngIf="child.external" style="height: 100%;
                width: 100%;
                display: inline-block;">
                  <span *ngIf="child.iconType==='image'" class="pcoded-micon">
                  </span>
                  {{ child.title }}</a>
                <a igxTreeNodeLink [routerLink]="child.url" (click)="navigate(child)" *ngIf="!child.external" style="height: 100%;
                width: 100%;
                display: inline-block;">
                  <span *ngIf="child.iconType==='image'" class="pcoded-micon">
                  </span>
                  {{ child.title }}</a>
              </igx-tree-node>
            </igx-tree-node>
          </igx-tree>
        </div>
      </ng-template>
      <ng-template igxDrawerMini *ngIf="windowWidth > 1023">
        <div class="collapse-btn-wrapper">
          <button igxIconButton="flat" *ngIf="drawer.isOpen === true" igxToggleAction="navigation"
            (click)="drawer.toggle()">
            <igx-icon family="material" class="collapse-btn-icon">arrow_back</igx-icon>
          </button>
          <button igxIconButton="flat" *ngIf="drawer.isOpen === false" igxToggleAction="navigation"
            (click)="drawer.toggle()">
            <igx-icon family="material" class="collapse-btn-icon">arrow_forward</igx-icon>
          </button>
        </div>
        <div>
          <span *ngFor="let child of navigations" igxDrawerItem [active]="child.title === selected" igxRipple
            (click)="navigate(child)">
            <a (click)="logOut()" *ngIf="child.id === 'logOut'">
              <span *ngIf="child.iconType==='image'" class="pcoded-micon">
                <img class="png-icon" src='{{ "assets/icons/" + child.icon }}'></span>
              <span *ngIf="child.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="child.icon"
                  class="child-menu-icon"></i></span>
            </a>
            <a [routerLink]="child.url" *ngIf="child.id !== 'logOut'">
              <span *ngIf="child.iconType==='image'" class="pcoded-micon">
                <img class="png-icon" src='{{ "assets/icons/" + child.icon }}'></span>
              <span *ngIf="child.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="child.icon"
                  class="child-menu-icon"></i></span>
            </a>
          </span>
        </div>
      </ng-template>
    </igx-nav-drawer>
  </div>
  <main igxFlex class="main-wrapper">
    <div class="top-item-wrapper">
      <button class="collapse-btn" igxIconButton="flat" *ngIf="drawer.isOpen === false && windowWidth < 1024"
        igxToggleAction="navigation" (click)="drawer.toggle()">
        <igx-icon family="material" class="collapse-btn-icon">arrow_forward</igx-icon>
      </button>
      <div class="top-sub-wrapper">
        <div class="label-wrapper">
          <div class="label-div">Notifications: </div>
          <div class="value-div warning">2</div>
        </div>
        <div class="label-wrapper" *ngIf="isScore">
          <div class="label-div">SAM Score: </div>
          <div class="value-div">{{score}}</div>
        </div>
        <div class="label-wrapper" *ngIf="houseSAMScore.isHouse">
          <div class="label-div">+/- Target Completion: </div>
          <div class="value-div" [ngClass]="houseSAMScore.color === '#FF0000' ? 'warning' : ''">{{houseSAMScore.target}}
          </div>
        </div>
        <div class=" label-wrapper" *ngIf="houseSAMScore.isHouse">
          <div class="label-div">Cycle Time:</div>
          <div class="value-div">{{houseSAMScore.cycleTime}}</div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <router-outlet>
        <div style="min-height: calc(100%);max-height: calc(100%);box-shadow:none;" *ngIf="isViewerHidden">
          <div id="viewer-host">
            <gc-activereports-viewer class="daily-activity-report">
            </gc-activereports-viewer>
          </div>
        </div>
      </router-outlet>
    </div>
  </main>
</div>