import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class UsersService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('user');
    }
    getAll(action: any, division: any = null, user: any = null): Observable<any> {
        if (division) {
            return this.http.get(`${this.path}?action=${action}&division=${division}`);
        }
        else if (user) {
            return this.http.get(`${this.path}?action=${action}&user=${user}`);
        }
        else if (action !== '') {
            return this.http.get(`${this.path}?action=${action}`);
        }
        else {
            return this.http.get(`${this.path}`);
        }
    }
    get(pk: number): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByGroup(group: number): Observable<any> {
        return this.http.get(`${this.path}?action=group&group=${group}`);
    }
    getRecall(pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    warrantyManagerBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=warrantyManagerBySubdivision&subdivision=` + subdivision);
    }
    subdivisionMaintenanceBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=subdivisionMaintenanceBySubdivision&subdivision=` + subdivision);
    }
    getBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=subdivision&subdivision=` + subdivision);
    }
    getWarrantyNotificationBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=warrantybysubdivision&subdivision=` + subdivision);
    }
    copy(action: string, targetUser: number, sourceUser: number): Observable<any> {
        return this.http.post(`${this.path}/copy?action=${action}&targetUser=${targetUser}&sourceUser=${sourceUser}`, "");
    }
    activeDeactive(action: string, currentuser: any): Observable<any> {
        return this.http.post(`${this.path}/activedeactive?action=${action}`,currentuser);
    }
    getByUUID(uuid?: string): Observable<any> {
        return this.http.get(`${this.path}/uuid/${uuid}`);
    }
    getCurrentUser(): Observable<any> {
        return this.http.get(`${this.path}?action=loggedinuser`);
    }
    recallUser(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    delete(id: any) {
        return this.http.delete<any>(`${this.path}/${id}`);
    }
    update(data: any) {
        return this.http.put(`${this.path}/${data.usr_pk}`, data);
    }
    create(data: any) {
        return this.http.post(`${this.path}`, data);
    }
    getByCompanyByUser(company: number): Observable<any> {
        return this.http.get(`${this.path}?action=userbyuser&company=${company}`)
    }
}