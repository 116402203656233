import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class PoRequestService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('po-request');
    }

    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByHouse(action: any, pk: any): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&house=${pk}`);
    }
    getRecall(pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    recallPORequest(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    update(pk: any, model: any): Observable<any> {
        return this.http.put<any>(`${this.path}/${pk}`, model);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    getBySuperAdmin(): Observable<any> {
        return this.http.get(`${this.path}?action=superadmin`);
    }
    bulkUpdate(model: any) {
        return this.http.put<any>(`${this.path}/UpdateTaskPO`, model);
    }
}