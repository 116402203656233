import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectSubdivisionById' })
export class SelectSubdivisionById implements PipeTransform {
    transform(value: any[], sub_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.sub_pk === sub_pk;
            }).map(o => o.sub_name);
        } else {
            return 'No data found';
        }
    }
}
