import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectGarageTypeById' })
export class SelectGarageTypeById implements PipeTransform {
    transform(value: any[], gar_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.gar_pk === gar_pk;
            }).map(o => o.gar_type);
        } else {
            return 'No data found';
        }
    }
}
