import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IColumnPipeArgs, IgxGridComponent, Transaction, TransactionType } from 'igniteui-angular';
import { AccessLevel } from 'src/app/constants';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { BackChargeService } from 'src/app/core/services/back-charge.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { CostCodeService } from 'src/app/core/services/costcode.service';
import { HouseService } from 'src/app/core/services/house.service';
import { LoadingService } from 'src/app/core/services/loader.service';
import { PoRequestService } from 'src/app/core/services/po-request.service';
import { PORequestHistoryService } from 'src/app/core/services/porequest-history-.service';
import { VarianceService } from 'src/app/core/services/variance.service';
import { VendorTypeService } from 'src/app/core/services/vendor-type.service';
import { ToasterService } from 'src/app/toaster/toaster.service';
import { ConfirmationBoxComponent } from '../../dialogs/confirmation-box/confirmation-box.component';
import { PORequestAttachmentService } from 'src/app/core/services/porequest-attachment.service';
import { AlertDialogComponent } from '../../dialogs/alert-dialog/alert-dialog.component';
import { CompanyApprovalLevelService } from 'src/app/core/services/company-approval-level.service';
import { VoiceRecognitionService } from 'src/app/core/services/speech-recognitation.service';

const PipeArgs: IColumnPipeArgs = {
  format: 'MM/dd/yyyy',
  timezone: 'UTC',
  digitsInfo: '1.1-2'
}

@Component({
  selector: 'app-porequest',
  templateUrl: './porequest.component.html',
  styleUrls: ['./porequest.component.scss']
})
export class PorequestComponent implements OnInit, AfterViewInit, AfterContentInit {

  //#region Child Property  
  @ViewChild('parentForm', { static: false }) public parentForm!: NgForm;
  @ViewChild('gridChildEntry', { read: IgxGridComponent, static: true }) public gridChildEntry!: IgxGridComponent;
  @ViewChild('porUpload', { static: false }) public porUpload!: ElementRef;
  //#endregion

  //#region Property
  newBKCId: any = -1;
  pipeArgs = PipeArgs;
  isModify: any = false;
  porPK: any = -1;
  poRequest: any = {};
  autoApproveAmount: any = 0;
  house: any = {};
  poRequestData: any[] = [];
  vendorList: any[] = [];
  poRequestVendor: any[] = [];
  poRequestCostcodeList: any[] = [];
  poRequestCostcodes: any[] = [];
  varianceList: any[] = []
  bkcVendorList: any[] = [];
  bkcCostCodeList: any[] = [];
  variances: any[] = [];
  vendorTypes: any[] = [];
  backCharges: any[] = [];
  history: any[] = [];
  poDocuemtData: any[] = [];
  selectedRow: any = {};
  selectedRowsCount: any = 0;
  trackProcess: any = {
    totalProcess: 0,
    completedProcess: 0,
    failed: false
  };
  permission: any = {
    poRequest: 0,
    poRequestPurchaseComment: 0,
    taskPORequest: 0
  }
  currentUser: any;
  checkoutUser: any = false;
  public isAdministrator = false;
  public isAssistanceBuilder = false;
  public isBuilder = false;
  rolesEnum: typeof AccessLevel = AccessLevel;
  public innerWidth: any;
  public isSaved = false;
  public porUploadModel: any = {};
  isHousePORequest: any = true;
  isPermissionModify: boolean = true;
  //#endregion Property

  constructor(private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private loader: LoadingService,
    private toaster: ToasterService,
    private poRequestService: PoRequestService,
    private houseService: HouseService,
    private contactService: ContactService,
    private costCodeService: CostCodeService,
    private varianceService: VarianceService,
    private poRequestAttachmentService: PORequestAttachmentService,
    private vendorTypeService: VendorTypeService,
    private bachChargeService: BackChargeService,
    private poRequestHistoryService: PORequestHistoryService,
    private companyApprovalLevelService: CompanyApprovalLevelService,
    private authenticationService: AuthenticationService,
    public voiceRecognitionService: VoiceRecognitionService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PorequestComponent>) {
    this.voiceRecognitionService.init();
    this.poRequest = data.poRequest;
    this.house = data.house;
    this.isHousePORequest = data.isHousePORequest;
    this.isModify = data.isModify;
    this.porPK = this.poRequest.por_pk;
    this.poRequestData = data.lstPORequestList;
  }
  async ngOnInit(): Promise<void> {
    this.permission.poRequest = await this.authenticationService.getAccessLevel('060.11');
    this.permission.poRequestPurchaseComment = await this.authenticationService.getAccessLevel('152.05');
    this.authenticationService.getUserInfo().then(value => {
      this.currentUser = value;
    });
    this.authenticationService.allRole().then(value => {
      this.isAdministrator = value.Administrator;
      this.isAssistanceBuilder = value['Assistant Builder'];
      this.isBuilder = value.Builder;
    });
    this.getData();

    if (!this.isHousePORequest) {
      if (this.poRequest.isManagement && this.poRequest.por_isreject === false && this.poRequest.por_approve === false) {
        this.authenticationService.getAccessLevel('152.03').then(value => {
          if (value < this.rolesEnum.ModifyOnly) {
            this.parentForm.controls['po'].disable();
            this.parentForm.controls['comment'].disable();
            this.parentForm.controls['purComment'].disable();
            this.parentForm.controls['rejectedreason'].disable();
            this.parentForm.controls['isApprove'].disable();
            this.parentForm.controls['urgent'].disable();
            this.parentForm.controls['isRejected'].disable();
            this.isPermissionModify = false
          }
        });
      } else if (this.poRequest.por_isreject && this.poRequest.por_approve === false) {
        this.authenticationService.getAccessLevel('152.04').then(value => {
          if (value < this.rolesEnum.ModifyOnly) {
            this.parentForm.controls['purComment'].disable();
            this.parentForm.controls['rejectedreason'].disable();

            this.parentForm.controls['isApprove'].disable();
            this.parentForm.controls['urgent'].disable();
            this.parentForm.controls['isRejected'].disable();
            this.isPermissionModify = false
          }
        });
      } else {
        this.authenticationService.getAccessLevel('152').then(value => {
          if (value < this.rolesEnum.ModifyOnly) {
            this.parentForm.controls['po'].disable();
            this.parentForm.controls['comment'].disable();
            this.parentForm.controls['purComment'].disable();
            this.parentForm.controls['rejectedreason'].disable();
            this.parentForm.controls['isApprove'].disable();
            this.parentForm.controls['urgent'].disable();
            this.parentForm.controls['isRejected'].disable();
            this.isPermissionModify = false
          }
        });
      }

      this.authenticationService.getAccessLevel('152.05').then(value => {
        if (value < this.rolesEnum.ModifyOnly) {
          this.parentForm.controls['purComment'].disable();
        }
      });

    }

    if (this.poRequest.por_iscompleted) {
      this.parentForm.controls['isCompleted'].disable();
      this.parentForm.controls['urgent'].disable();
      this.parentForm.controls['isRejected'].disable();
      this.parentForm.controls['vendorType'].disable();
      this.parentForm.controls['contact'].disable();
      this.parentForm.controls['variance'].disable();
      this.parentForm.controls['eco'].disable();
      this.parentForm.controls['vendorId'].disable();
      this.parentForm.controls['ccdDesc'].disable();
      this.parentForm.controls['ccdId'].disable();
      this.parentForm.controls['po'].disable();
      this.parentForm.controls['poAdmin'].disable();
      this.parentForm.controls['amount'].disable();
      this.parentForm.controls['vendorInvoice'].disable();
      this.parentForm.controls['rejectedreason'].disable();
      this.parentForm.controls['purComment'].disable();
      this.parentForm.controls['comment'].disable();
    }

  }
  processStart() {
    this.loader.show();
    this.trackProcess.totalProcess = this.trackProcess.totalProcess + 1;
  }
  processEnd(isFailed: any = false) {
    this.trackProcess.completedProcess = this.trackProcess.completedProcess + 1;
    if (isFailed) {
      this.trackProcess.failed = true;
    }
    this.checkComplte();
  }
  checkComplte() {
    if (this.trackProcess.completedProcess === this.trackProcess.totalProcess) {
      if (this.trackProcess.failed) {
        // this.toaster.show('error', '', 'Something went to wrong.');
      }
      this.loader.hide();
    }
  }
  getData() {
    this.trackProcess = {
      totalProcess: 0,
      completedProcess: 0,
      failed: false
    };
    setTimeout(() => {
      if (this.isHousePORequest && this.currentUser && this.currentUser.usr_pk === this.house.hou_usrcofk) {
        this.checkoutUser = true;
      }
    });
    this.getVendors();
    this.getVariance();
    this.getCostCode();
    this.getVendorType();
    this.getBackchange();
    this.getHistory();
    this.getAttachment();
    if (this.isHousePORequest) {
      this.getCompanyApprovalLevel();
    }
  }
  getCompanyApprovalLevel() {
    this.processStart();
    this.companyApprovalLevelService.getByUser(this.house.hou_comfk).subscribe(res => {
      if (res && res.length > 0) {
        this.autoApproveAmount = res[0].ApproveAmount;
      }
      this.processEnd();
    }, error => {
      this.processEnd(true);
    });
  }
  getAttachment() {
    this.processStart();
    this.poRequestAttachmentService.getByPORequest(this.poRequest.por_pk).subscribe(res => {
      this.poDocuemtData = res;
      this.processEnd();
    }, error => {
      this.processEnd(true);
    });
  }
  getVendors() {
    this.processStart();
    let subPk = 0;
    if (this.isHousePORequest) {
      subPk = this.house.hou_subfk;
    } else {
      subPk = this.poRequest.sub_pk;
    }
    this.contactService.getVendorTypeBySubdivision(subPk).subscribe((vendors: any) => {
      this.vendorList = vendors;
      if (this.poRequest && this.poRequest.por_vetfk) {
        this.poRequestVendor = this.vendorList.filter(res => res.vet_pk === this.poRequest.por_vetfk);
      }
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
      console.log(error);
    }, () => {

    })
  }
  getCostCode() {
    this.processStart();
    this.costCodeService.selectByVarianceContact(-1, -1).subscribe((costCodes: any) => {
      this.poRequestCostcodeList = costCodes;
      if (this.poRequest && this.poRequest.por_varfk && this.poRequest.por_varfk > 0 && this.poRequest.por_confk && this.poRequest.por_confk > 0) {
        this.poRequestCostcodes = this.poRequestCostcodeList.filter(res => res.var_pk === this.poRequest.por_varfk && res.con_pk === this.poRequest.por_confk);
      }
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
      console.log(error);
    }, () => {

    })
  }
  getVariance() {
    this.processStart();
    this.varianceService.getAll().subscribe((variances: any[]) => {
      if (variances && variances.length > 0) {
        this.varianceList = variances;
        if (variances.length > 0) {

          if (this.isHousePORequest) {
            if (this.house.hou_jobid === '000' || this.house.hou_jobid === '0') {
              this.variances = this.varianceList.filter((variance: any) => variance.var_id === 'G' || variance.var_id === 'B');
            }
            else if (this.house.hou_dt_closing !== null && this.house.hou_dt_closing !== '') {
              let closingDate: any = new Date(this.house.hou_dt_closing);
              let currentDate: any = new Date();
              const totalDays: any = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(closingDate.getFullYear(), closingDate.getMonth(), closingDate.getDate())) / (1000 * 60 * 60 * 24));
              if (totalDays > 45) {
                this.variances = this.varianceList.filter((variance: any) => variance.var_id !== 'G');
              }
              if (totalDays < 365) {
                this.variances = this.varianceList.filter((variance: any) => variance.var_id === 'W' || variance.var_id === 'B' || variance.var_id === '9');
              }
              if (totalDays > 365) {
                this.variances = this.varianceList.filter((variance: any) => variance.var_id === 'X' || variance.var_id === 'B' || variance.var_id === '9');
              }
            }
            else {
              this.variances = variances.filter((variance: any) => variance.var_id !== 'G' && variance.var_id !== 'W' && variance.var_id !== 'X' && variance.var_id !== '9');
            }
          } else {
            this.variances = variances;
          }
        }
      }
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
      console.log(error);
    }, () => {

    });
  }
  getVendorType() {
    this.processStart();
    this.vendorTypeService.getAll().subscribe((vendorTypes: any) => {
      if (vendorTypes.length > 0) {
        this.vendorTypes = vendorTypes;
      }
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
      console.log(error);
    }, () => {

    });
  }
  getBackchange() {
    this.processStart();
    this.bachChargeService.getByPORequest(this.porPK).subscribe((res: any) => {
      this.backCharges = res;
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
    });
  }
  getHistory() {
    this.processStart();
    this.poRequestHistoryService.getByPORequest(this.poRequest.por_pk, null).subscribe((res: any) => {
      this.history = res;
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
    });
  }
  ngAfterContentInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  save() {
    if (this.isHousePORequest) {
      this.isSaved = true;
      if (this.parentForm.invalid) {
        (<any>Object).values(this.parentForm.controls).forEach((control: any) => {
          control.markAsTouched();
          if (control.controls) {
            control.markAsTouched();
          }
        });
        return;
      }
      if (this.parentForm.valid && this.hasValidForm() && this.checkLessBackchargeAmount() && this.checkDependentField()) {

        if (this.isModify) {
          this.updatePORequest();
        }
        else {
          this.createPORequest();
        }
      }
    } else {
      if (this.parentForm.valid) {
        let isValid = true;
        if (this.poRequest.por_approve && (this.poRequest.por_po === '' || this.poRequest.por_po === null)) {
          isValid = false;
        } else if (this.poRequest.por_isreject && (this.poRequest.por_rejectreason === '' || this.poRequest.por_rejectreason === null)) {
          isValid = false;
        }
        if (isValid) {
          this.dialogRef.close({ ...this.poRequest });
        } else {
          let message = 'Please fill all the required field.';
          this.openAlertBox(message);
        }
      } else {
        let message = 'Please fill all the required field.';
        this.openAlertBox(message);
      }
    }
  }
  private updatePORequest() {
    this.loader.show();
    this.poRequestService.update(this.poRequest.por_pk, this.poRequest).subscribe(() => {
      if (this.hasVariance('B')) {
        this.saveBackCharge();
      }
      else {
        this.toaster.show('success', '', 'Record updated sucessfully');
        this.dialogRef.close(true);
      }
      this.loader.hide();
    }, () => {
      this.loader.hide();
    });
  }
  private createPORequest() {
    this.loader.show();
    this.poRequestService.create(this.poRequest).subscribe((res) => {
      this.poRequest.por_pk = res;
      if (this.hasVariance('B')) {
        this.saveBackCharge(res);
      }
      else {
        if (this.autoApproveAmount >= this.poRequest.por_amount) {
          this.poRequest.por_iscompleted = true;
          this.updatePORequest();
        }
        this.toaster.show('success', '', 'Record updated sucessfully');
        this.dialogRef.close(true);
      }
      this.loader.hide();
    }, () => {
      this.loader.hide();
    });
  }

  saveBackCharge(isNew: any = null) {
    const backCharges: any[] = this.gridChildEntry.transactions.getAggregatedChanges(true).map((x: any) => x.newValue);
    if (isNew !== null) {
      backCharges.filter(x => x.bkc_porfk = isNew);
    }
    this.bachChargeService.bulkInsert(backCharges).subscribe(res => {
      if (this.autoApproveAmount >= this.poRequest.por_amount) {
        this.poRequest.por_iscompleted = true;
        this.updatePORequest();
      }
      this.toaster.show('success', '', 'Record updated sucessfully');
      this.dialogRef.close(true);
      this.loader.hide();
    }, error => {
      this.loader.hide();
    })
  }
  hasValidForm() {
    if (this.hasVariance('B')) {
      const count: any = this.gridChildEntry.dataRows().length;
      const bkcChanges: any[] = this.gridChildEntry.transactions.getAggregatedChanges(true).map((x: any) => x.newValue).filter(res => {
        if (res.bkc_confk === null || res.bkc_ccdfk === null || res.bkc_vetfk === null || res.bkc_confk < 1 || res.bkc_ccdfk < 1 || res.bkc_vetfk < 1) {
          return true;
        }
        return false;
      });

      if (count === 0) {
        this.toaster.show('warning', '', 'Back charge is required for this variance.');
        return false;
      }
      else if (bkcChanges && bkcChanges.length > 0) {
        this.toaster.show('warning', '', 'Please complete all backcharge entries.');
        return false;
      }
      return true;
    }
    return true;
  }
  checkLessBackchargeAmount() {
    if (!this.hasVariance('B')) {
      return true;
    }
    else {
      if (this.backCharges && this.backCharges.length > 0) {
        let totalAmount: any = 0;
        let blankBackcharge: any = false;
        this.backCharges.filter(x => {
          totalAmount += x.bkc_amount;
          if (!blankBackcharge && (x.bkc_amount === '' || x.bkc_amount === null || x.bkc_amount === 0)) {
            blankBackcharge = true;
          }
          return true;
        });
        if (blankBackcharge) {
          this.toaster.show('warning', '', 'Please enter valid BackCharge Amount.');
          return false;
        }
        totalAmount = totalAmount * -1;
        if (totalAmount > this.poRequest.por_amount) {
          this.toaster.show('warning', '', 'BackCharge amount can not be greater than PORequest amount.');
          return false;
        }
      }
    }
    return true;
  }
  checkDependentField() {
    let hasLaborCostCode: any = false;
    const costCode = this.poRequestCostcodes.filter(res => res.ccd_pk === this.poRequest.por_ccdfk);
    if (costCode && costCode.length > 0 && costCode[0].ccd_name.toLocaleLowerCase().includes('labor')) {
      hasLaborCostCode = true;
    }
    let hasMaterialVar: any = this.hasVariance('M');
    let hasBackchargeVar: any = this.hasVariance('B');
    let hasWarrantyVar: any = this.hasVariance('W');
    let hasCustomOptionVar: any = this.hasVariance('C');
    if (hasLaborCostCode && (this.poRequest.por_invoice === '' || this.poRequest.por_invoice === null)) {
      this.toaster.show('warning', '', 'Vendor invoice# can not be blank for this cost code.');
      return false;
    }
    if (hasCustomOptionVar && (this.poRequest.por_eco === '' || this.poRequest.por_eco === null)) {
      this.toaster.show('warning', '', 'Change Order # can not be blank for this variance.');
      return false;
    }
    if (hasWarrantyVar && (this.poRequest.por_eco === '' || this.poRequest.por_eco === null)) {
      this.toaster.show('warning', '', 'Warranty # can not be blank for this variance.');
      return false;
    }
    if (hasLaborCostCode && (this.poRequest.por_amount === '' || this.poRequest.por_amount === null || this.poRequest.por_amount <= 0)) {
      if (!hasBackchargeVar && !hasMaterialVar) {
        this.toaster.show('warning', '', 'Amount can not be valid for this cost code.');
        return false;
      }
      else if (this.poRequest.por_amount === 0 && (hasBackchargeVar || hasMaterialVar)) {
        this.toaster.show('warning', '', 'Amount can not be blank for this cost code.');
        return false;
      }
    }
    return true;
  }
  getVendorName(value: any): any {
    return this.vendorList.find(o => o.con_pk === value) ? this.vendorList.find(o => o.con_pk === value).con_name : '';
  }
  speechStart(event: any, type: any) {
    this.voiceRecognitionService.text = '';
    this.voiceRecognitionService.start();
    event.currentTarget.children[0].className = 'pulse-ring pulse-animation';
  }
  speechStop(event: any, type: any) {
    this.voiceRecognitionService.stop();
    event.currentTarget.children[0].className = 'pulse-ring';
    if (type === 'rejectreason') {
      this.poRequest.por_rejectreason = this.voiceRecognitionService.text;
    }
    else if (type === 'purchasecomment') {
      this.poRequest.por_purchase_comments = this.voiceRecognitionService.text;
    }
    else if (type === 'comment') {
      this.poRequest.por_comments = this.voiceRecognitionService.text;
    }
    this.voiceRecognitionService.text = '';
  }
  handleRowSelection(event: any) {
    this.selectedRow = {};
    this.selectedRowsCount = event.newSelection.length;
    this.selectedRow = event.newSelection[0];

    this.bkcVendorList = this.vendorList.filter(res => res.vet_pk === this.selectedRow.bkc_vetfk);
    this.bkcCostCodeList = this.poRequestCostcodeList.filter(res => res.con_pk === this.selectedRow.bkc_confk && res.var_pk === this.poRequest.por_varfk);
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const ext = file.name.split('.').pop()?.toLocaleLowerCase();
    if (ext == "txt" || ext == "doc" || ext == "docx"
      || ext == "pdf" || ext == "xls" || ext == "xlsx"
      || ext == "ppt" || ext == "pptx" || ext == "jpg"
      || ext == "jpeg" || ext == "png" || ext == "gif"
      || ext == "bmp") {
      if (file) {
        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            const bytes = e.target.result.split('base64,')[1];
            this.porUploadModel.pot_pk = -1;
            this.porUploadModel.pot_houfk = this.house.hou_pk;
            this.porUploadModel.pot_porfk = this.poRequest.por_pk;
            this.porUploadModel.pot_filedata = bytes;
            this.porUploadModel.pot_originalname = file.name;
            this.porUploadModel.pot_datecreated = new Date();
            this.porUploadModel.pot_client = 'SAM';
            this.porUploadModel.pot_confk = this.currentUser.usr_pk;
            this.porUploadModel.pot_createdby = this.currentUser.usr_pk.toString();
            this.porUploadModel.pot_createddate = new Date();
          };
        }, 500);
      }
    } else {
      // this.dialog.open(AlertDialogComponent, {
      //   disableClose: true,
      //   data: { message: 'File extension not valid. Please select another file' }
      // });
      let message = 'File extension not valid. Please select another file';
      this.openAlertBox(message);
    }
  }
  uploadFile(event: any) {
    this.processStart();
    this.poRequestAttachmentService.create(this.porUploadModel, false).subscribe(result => {
      this.getAttachment();
      this.processEnd();
    }, error => {
      this.processEnd(true);
    });
  }
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  closeDialog() {
    if (this.parentForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        disableClose: true,
        data: { message: 'Do you wish to save changes?', cellData: '' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.save();
        } else {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }
  hasVariance(varianceId: any) {
    if (this.poRequest && this.poRequest.por_varfk) {
      if (varianceId === 'C') {
        const variance = this.varianceList.filter(res => res.var_pk === this.poRequest.por_varfk);
        if (variance && variance.length > 0 && variance[0].var_name.includes(varianceId)) {
          return true;
        }
      }
      else {
        const variance = this.varianceList.filter(res => res.var_pk === this.poRequest.por_varfk);
        if (variance && variance.length > 0 && variance[0].var_id === varianceId) {
          return true;
        }
      }
    }
    return false;
  }
  changeVendorType(event: any) {
    this.poRequestVendor = [];
    this.poRequest.por_confk = null;
    this.poRequestVendor = this.vendorList.filter(res => res.vet_pk === event.newSelection);
  }
  changeVendor(event: any) {
    this.poRequest.por_ccdfk = null;
    if (this.poRequest && this.poRequest.por_varfk && this.poRequest.por_varfk > 0) {
      this.poRequestCostcodes = this.poRequestCostcodeList.filter(res => res.var_pk === this.poRequest.por_varfk && res.con_pk === event.newSelection);
    }
  }
  changeVariance(event: any) {
    this.poRequest.por_ccdfk = null;
    if (event.newSelection > 0 && this.poRequest.por_confk && this.poRequest.por_confk > 0) {
      this.poRequestCostcodes = this.poRequestCostcodeList.filter(res => res.var_pk === event.newSelection && res.con_pk === this.poRequest.por_confk);
    }
  }
  onbkcAmountKeydown(event: any, cell: any) {
    if (event.key === 'Backspace'
      || event.key === 'Delete'
      || event.key === 'Escape'
      || event.key === 'Enter') {
      return true
    }
    if (isNaN(event.key)) {
      return false;
    }
    let totalAmount: any = 0;
    this.backCharges.filter(x => {
      if (x.bkc_pk !== cell.row.data.bkc_pk) {
        totalAmount += x.bkc_amount;
      }
      return true;
    });
    if (totalAmount > 0) {
      totalAmount = totalAmount * -1;
    }
    let inputVal: number = Number(event.key * -1);
    if (cell.editValue !== null) {
      inputVal = cell.editValue + event.key;
      if (inputVal > 0) {
        inputVal = inputVal * -1;
      }
    }
    totalAmount = Number(totalAmount) + Number(inputVal);
    totalAmount = totalAmount * -1;
    if (totalAmount > Number(this.poRequest.por_amount)) {
      this.toaster.show('warning', '', 'BackCharge amount can not be greater than PORequest amount.');
      return false;
    }
    cell.editValue = inputVal;
    return false;
  }
  onCellSelected(event: any) {
    if (event.cell.column.editable && !event.cell.editMode && !this.poRequest.por_iscompleted) {
      event.cell.editMode = true;
    } else {
      event.cell.editMode = false;
    }
  }
  changeHeaderChildgrid(event: any) {
    const bkcChanges: any[] = this.gridChildEntry.transactions.getAggregatedChanges(true).map((x: any) => x.newValue).filter(res => {
      if (res.bkc_confk === null || res.bkc_ccdfk === null || res.bkc_vetfk === null || res.bkc_confk < 1 || res.bkc_ccdfk < 1 || res.bkc_vetfk < 1) {
        return true;
      }
      return false;
    });
    if (bkcChanges && bkcChanges.length > 0) {
      this.toaster.show('warning', '', 'Vendor Type, Vendors/Trades and Cost code is required. Please enter valid value.');
      event.cancel = true;
      event.newSelection = -1;
      return;
    }
    else {
      let highWarrantyNumber: any = 0;
      this.gridChildEntry.dataRows().forEach(element => {
        const num = Number(element.data.bkc_invoice.match(/\d+/g)[0]);
        if (num > highWarrantyNumber) {
          highWarrantyNumber = num;
        }
      });
      highWarrantyNumber++;
      const backCharge: any = {
        bkc_pk: this.newBKCId,
        bkc_porfk: this.poRequest.por_pk,
        bkc_vetfk: event.newSelection,
        bkc_confk: null,
        bkc_ccdfk: null,
        bkc_invoice: this.getInvoiceString(highWarrantyNumber),
        bkc_amount: 0,
        bkc_approve: false,
        bkc_iscompleted: false,
        bkc_isreject: false,
        bkc_rejectreason: '',
        bkc_urgentpo: false,
        bkc_po: '',
        bkc_comments: '',
        kc_purchase_comments: '',
        bkc_approvedby: null,
        bkc_approveddate: null,
        bkc_createdby: this.currentUser.usr_pk,
        bkc_createddate: new Date(),
        bkc_updatedby: null,
        bkc_updateddate: null
      }

      const newTransaction: Transaction = {
        id: backCharge.bkc_pk,
        type: TransactionType.ADD,
        newValue: backCharge
      };
      this.gridChildEntry.transactions.add(newTransaction);
      this.newBKCId = this.newBKCId - 1;
      event.cancel = true;
      event.newSelection = -1;
    }
  }

  private getInvoiceString(highWarrantyNumber: any) {
    let invoice: any = 'BC-';
    if (this.house.hou_projectid) {
      invoice = invoice.toString() + '-' + this.house.hou_projectid.toString();
    }
    if (this.house.hou_jobid) {
      invoice = invoice.toString() + '-' + this.house.hou_jobid.toString();
    }
    if (highWarrantyNumber) {
      invoice = invoice.toString() + ('000' + highWarrantyNumber).slice(-3);
    }
    return invoice;
  }

  onKeyUpDuplicateCheck(event: any) {
    if (!this.isHousePORequest) {
      let isDuplicatePO = this.poRequestData.filter(x => x.por_po === this.poRequest.por_po && x.por_houfk === this.poRequest.por_houfk && x.isWarrantyHouseOLD === this.poRequest.isWarrantyHouseOLD)
      if (isDuplicatePO.length > 0) {
        const message = 'PO # as already exists.';
        this.poRequest.por_po = '';
        this.openAlertBox(message);
      }
    }
  }

  openAlertBox(message: string) {
    this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      data: { message: message }
    });
  }

}
