import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SelectPhaseById } from './phase-pipe';
import { SelectWarrantyScheduleUserById, SelectWarrantySignOffById, SelectWarrantyUserTypeById } from './warranty-pipe';
import { SelectCompanyById } from './company-pipe';
import { SelectGroupById } from './group-pipe';
import { SelectPOIGroupById } from './poi-group-pipe';
import { SelectContactByContactPk } from './contact.pipe';
import { SelectMaintenanceHouseById } from './maintenance-house-pipe';
import { SelectRoomById } from './room-pipe';
import { SelectVendorById } from './vendor-pipe';
import { SelectTimeWindowById } from './time-window-pipe';
import { SelectCostCodeById } from './costcode-pipe';
import { SelectVendorTypeById } from './vendor-type-pipe';
import { SelectVarianceById } from './variance-pipe';
import { SelectCostCodeIdById } from './costcode-id-pipe';
import { SelectUserById } from './user-pipe';
import { SelectSubdivisionById } from './subdivision-pipe';
import { SelectCityById } from './city-pipe';
import { SelectPlanById } from './plan-pipe';
import { SelectGarageTypeById } from './garage-type-pipe';

@NgModule({
    imports: [
        // dep modules
    ],
    declarations: [
        SelectPhaseById,
        SelectGroupById,
        SelectCompanyById,
        SelectWarrantySignOffById,
        SelectWarrantyUserTypeById,
        SelectWarrantyScheduleUserById,
        SelectPOIGroupById,
        SelectContactByContactPk,
        SelectMaintenanceHouseById,
        SelectRoomById,
        SelectVendorById,
        SelectTimeWindowById,
        SelectCostCodeById,
        SelectVendorTypeById,
        SelectVarianceById,
        SelectCostCodeIdById,
        SelectUserById,
        SelectSubdivisionById,
        SelectCityById,
        SelectPlanById,
        SelectGarageTypeById
    ],
    exports: [
        SelectPhaseById,
        SelectGroupById,
        SelectCompanyById,
        SelectWarrantySignOffById,
        SelectWarrantyUserTypeById,
        SelectWarrantyScheduleUserById,
        SelectPOIGroupById,
        SelectContactByContactPk,
        SelectMaintenanceHouseById,
        SelectRoomById,
        SelectVendorById,
        SelectTimeWindowById,
        SelectCostCodeById,
        SelectVendorTypeById,
        SelectVarianceById,
        SelectCostCodeIdById,
        SelectUserById,
        SelectSubdivisionById,
        SelectCityById,
        SelectPlanById,
        SelectGarageTypeById
    ],
    providers: [
        DatePipe,
        DecimalPipe
    ],
})
export class ApplicationPipesModule {
}
