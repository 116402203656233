<igx-card-header class="dialog-header">
    {{isModify ? 'Update ': 'Add ' + cardHeader}}
</igx-card-header>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="    --component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-vendor" (window:resize)="onResize($event)" class="p-2" style="height: calc(100% - 120px);">
    <form class="form-wrapper" id="dialog-appointment" #appointmentForm="ngForm" style="height: 100%;overflow: auto;">
        <igx-card>
            <igx-card-content>
                <table class="mt-2 column" style="width: 100%;height: 100%;">
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">Type :</label>
                        </td>
                        <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                            <div mat-dialog-content>
                                <igx-simple-combo [(ngModel)]="cardHeader" [data]="types" #eventType1="ngModel"
                                    name="eventType1" [displayKey]="'display'" [valueKey]="'display'">
                                </igx-simple-combo>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">Subject :</label>
                        </td>
                        <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                            <div mat-dialog-content>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="appObj.app_subject"
                                        #subject="ngModel" name="subject" type="text" required />
                                </igx-input-group>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">Location :</label>
                        </td>
                        <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                            <div mat-dialog-content>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="appObj.app_location"
                                        #location="ngModel" name="location" type="text" required />
                                </igx-input-group>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">Start time :</label>
                        </td>
                        <td class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <div mat-dialog-content>
                                <igx-date-picker [(ngModel)]="appObj.app_startdatetime" [mode]="'dialog'"
                                    name="startDate" #startDate="ngModel">
                                </igx-date-picker>
                                <div *ngIf="startDate.invalid && (startDate.dirty || startDate.touched)" class="alert">
                                    <div *ngIf="startDate.errors?.['required']">Required </div>
                                </div>
                            </div>
                        </td>
                        <td class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <igx-time-picker #startTimePicker="ngModel" name="startTimePicker"
                                [disabled]="appObj.app_alldayevent" [(ngModel)]="app_startTime"
                                [ngModelOptions]="{standalone: true}" [mode]="mode" [spinLoop]='false'
                                [inputFormat]="format" [itemsDelta]="{ hours: 1, minutes: 30 }">
                            </igx-time-picker>
                        </td>
                        <td class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <div mat-dialog-content>
                                <igx-checkbox [(ngModel)]="appObj.app_alldayevent" #allDayEvent="ngModel"
                                    name="allDayEvent">All day event
                                </igx-checkbox>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">End time :</label>
                        </td>
                        <td class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <div mat-dialog-content>
                                <igx-date-picker [(ngModel)]="appObj.app_enddatetime" [mode]="'dialog'" name="endDate"
                                    #endDate="ngModel">
                                </igx-date-picker>
                                <div *ngIf="endDate.invalid && (endDate.dirty || endDate.touched)" class="alert">
                                    <div *ngIf="endDate.errors?.['required']">Required
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <igx-time-picker #endTimePicker="ngModel" name="endTimePicker" [(ngModel)]="app_endTime"
                                [ngModelOptions]="{standalone: true}" [mode]="mode" [spinLoop]='false'
                                [disabled]="appObj.app_alldayevent" [inputFormat]="format"
                                [itemsDelta]="{ hours: 1, minutes: 30 }" [type]="'line'">
                            </igx-time-picker>
                        </td>
                        <td class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <div mat-dialog-content>
                                <igx-checkbox [(ngModel)]="appObj.app_remider" #reminder="ngModel"
                                    name="reminder">Reminder
                                </igx-checkbox>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2"> </td>
                        <td class="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                            <div mat-dialog-content>
                                <igx-input-group>
                                    <textarea igxInput cols="30" rows="5" [(ngModel)]="appObj.app_description"
                                        #description="ngModel" name="description"></textarea>
                                </igx-input-group>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2"> </td>
                        <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                            <div mat-dialog-actions class="d-flex justify-content-end button-sample mt-2">
                                <button igxButton="flat" igxRipple (click)="save()" class="me-2"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                                <button igxButton="flat" igxRipple (click)="closeDialog()"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </igx-card-content>
        </igx-card>
    </form>
</div>