import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectPlanById' })
export class SelectPlanById implements PipeTransform {
    transform(value: any[], pla_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.sdp_plafk === pla_pk;
            }).map(o => o.pla_name);
        } else {
            return 'No data found';
        }
    }
}
