import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class CostCodeService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('costcode');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    bulkUpdate(id: number, model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/${id}?action=bulk`, model);
    }
    selectCostCodeByVariance(varianceId: number): Observable<any> {
        return this.http.get(`${this.path}?action=variance&variance=` + varianceId)
    }
    selectByVarianceContact(varianceId: number, contactId: any): Observable<any> {
        return this.http.get(`${this.path}?action=variancevendor&variance=${varianceId}&contact=${contactId}`);
    }
    getByContact(contact: number): Observable<any> {
        return this.http.get(`${this.path}?action=contact&contact=${contact}`);
    }
    getByActivity(activity: number): Observable<any> {
        return this.http.get(`${this.path}?action=activity&activity=${activity}`);
    }
    getByHouse(house: number): Observable<any> {
        return this.http.get(`${this.path}?action=house&house=${house}`);
    }
}