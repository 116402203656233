<div id="http-loader" *ngIf="(loadingIndicator$ | async) === true">
  <div id="pause" class="d-flex align-items-center justify-content-center">
    <div id="spinner"></div>
  </div>
</div>

<div class="admin-top-content"
  *ngIf="loginDisplay !== null && loginDisplay === false && !isRedirectPage && (loadingIndicator$ | async) === false">
  <div>
    <a href="/">
      <img class="png-icon" src='{{ "assets/icons/SAMLogo.png" }}'>
    </a>
  </div>
  <button mat-raised-button class="login-button" (click)="loginRedirect()">Login</button>
</div>
<!-- <button mat-raised-button class="login-button" *ngIf="loginDisplay" (click)="logout()">Logout</button> -->
<a class="login-button" (click)="logout()"
  *ngIf="loginDisplay !== null && loginDisplay === true && !isRedirectPage && (loadingIndicator$ | async) === false">
  <span class="pcoded-micon">
    <img class="png-icon" src='assets/icons/right-from-bracket-solid.svg' style="height: 30px;width: 30px;"></span>
</a>

<app-toaster-container class="toaster-wrapper"></app-toaster-container>

<router-outlet
  *ngIf="loginDisplay !== null && loginDisplay === true && !isRedirectPage && (loadingIndicator$ | async) === false">
  <!-- <app-spinner></app-spinner> -->
  <loading></loading>
</router-outlet>