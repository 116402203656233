import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VarianceService extends ApiService<any> {

  constructor(protected http: HttpClient) {
    super('variance');
  }
  getAll(): Observable<any> {
    return this.http.get(`${this.path}`);
  }
  getRecall(pageIndex: any, pageSize: any): Observable<any> {
    return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }
  SelectVarianceByCostCode(costCodeId: number): Observable<any> {
    return this.http.get(`${this.path}?action=costcode&costCode=` + costCodeId)
  }
  insertVariance(model: any): Observable<any> {
    return this.http.post<any>(`${this.path}`, model);
  }
  bulkUpdate(id: any, model: any[]): Observable<any> {
    return this.http.put<any>(`${this.path}/${id}?action=bulk`, model);
  }
  recallVariance(model: any[]): Observable<any> {
    return this.http.put<any>(`${this.path}/recall`, model);
  }
  delete(id: any) {
    return this.http.delete<any>(`${this.path}/${id}`);
  }
  getActiveVariance(): Observable<any> {
    return this.http.get(`${this.path}?action=activevariance`);
  }
}
