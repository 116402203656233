import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class AppointmentService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('appointment');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByHouse(pk: any): Observable<any> {
        return this.http.get(`${this.path}?action=house&house=${pk}`);
    }
    getByUser(): Observable<any> {
        return this.http.get(`${this.path}?action=user`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    update(pk: any, model: any): Observable<any> {
        return this.http.put<any>(`${this.path}/${pk}`, model);
    }
    bulkUpdate(id: any, model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/${id}?action=bulk`, model);
    }
    getByOverview(subdivision: number, builder: number): Observable<any> {
        return this.http.get(`${this.path}?action=overview&subdivision=${subdivision}&builder=${builder}`);
    }
    delete(id: number): Observable<any> {
        return this.http.delete(`${this.path}/${id}`);
    }
}