import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { inherits } from 'util';
import { AuthenticationService } from '../services/authentication.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorAuthRequest, MsalService } from '@azure/msal-angular';
import { msalConfig } from 'src/app/auth-config';
import { AccountInfo, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { LocalService } from '../services/local.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { LoadingService } from '../services/loader.service';
import { error } from 'console';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private jwtTokenService: JWTTokenService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private loader: LoadingService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // svg icons
    if (req.responseType === 'text') {
      return next.handle(req);
    }
    const scopes: string[] = environment.authConfig.scopes;
    let account: any;

    if (!this.jwtTokenService.isTokenExpired() && this.jwtTokenService.jwtToken !== "") {
      const headers = req.headers.set(
        "Authorization",
        `Bearer ${this.jwtTokenService.jwtToken}`
      );

      // const requestClone = req.clone({ headers });
      // return next.handle(requestClone);
      return this.sendRequest(req.clone({ headers }), next);
    }
    else {
      if (!!this.authService.instance.getActiveAccount()) {
        this.authService
          .getLogger()
          .verbose("Interceptor - active account selected");
        account = this.authService.instance.getActiveAccount();
      } else {
        this.authService
          .getLogger()
          .verbose("Interceptor - no active account, fallback to first account");
        account = this.authService.instance.getAllAccounts()[0];
      }
      return this.acquireToken(this.msalGuardConfig.authRequest, scopes, account).pipe(
        switchMap((result: AuthenticationResult) => {
          this.authService
            .getLogger()
            .verbose("Interceptor - setting authorization headers");
          this.jwtTokenService.setToken(result.accessToken);

          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          // const requestClone = req.clone({ headers });
          // return next.handle(requestClone);
          return this.sendRequest(req.clone({ headers }), next);
        })
      );
    }
  }
  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    // No headers allowed in npm search request
    // const noHeaderReq = req.clone({ headers: new HttpHeaders() });
    
    return next.handle(req).pipe(
      tap(event => {
        // There may be other events besides the response.
      }, error => {
        console.log('interceptor error: ' + error);
      })
    );
  }
  /**
  * Try to acquire token silently. Invoke interaction if acquireTokenSilent rejected with error or resolved with null access token
  * @param authRequest Request
  * @param scopes Array of scopes for the request
  * @param account Account
  * @returns Authentication result
  */
  private acquireToken(
    authRequest: any,
    scopes: string[],
    account: AccountInfo
  ): Observable<AuthenticationResult> {
    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.authService
      .acquireTokenSilent({ ...authRequest, scopes, account })
      .pipe(
        catchError(() => {
          this.authService
            .getLogger()
            .error(
              "Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve."
            );
          return this.msalBroadcastService.inProgress$.pipe(
            take(1),
            switchMap((status: InteractionStatus) => {
              if (status === InteractionStatus.None) {
                return this.acquireTokenInteractively(authRequest, scopes);
              }

              return this.msalBroadcastService.inProgress$.pipe(
                filter(
                  (status: InteractionStatus) =>
                    status === InteractionStatus.None
                ),
                take(1),
                switchMap(() => this.acquireToken(authRequest, scopes, account))
              );
            })
          );
        }),
        switchMap((result: AuthenticationResult) => {
          if (!result.accessToken) {
            this.authService
              .getLogger()
              .error(
                "Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve."
              );
            return this.msalBroadcastService.inProgress$.pipe(
              filter(
                (status: InteractionStatus) => status === InteractionStatus.None
              ),
              take(1),
              switchMap(() =>
                this.acquireTokenInteractively(authRequest, scopes)
              )
            );
          }
          return of(result);
        })
      );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[]
  ): Observable<AuthenticationResult> {

    this.authService
      .getLogger()
      .verbose(
        "Interceptor - error acquiring token silently, acquiring by redirect"
      );
    const redirectStartPage = window.location.href;
    this.authService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage,
    });
    return EMPTY;
  }
}
