import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { LoadingService } from 'src/app/core/services/loader.service';
import { ToasterService } from 'src/app/toaster/toaster.service';
import { PORequestAttachmentService } from 'src/app/core/services/porequest-attachment.service';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent implements OnInit {
  @HostListener('window:resize', ['$event'])


  //#region Arrays and Objects
  attachmentUploadForm: FormGroup;
  windowWidth: any;
  public poData: any;
  public gridAttachmentData: any[] = [];
  poAttachment: any = {
    pot_pk: -1,
    pot_filedata: null,
    pot_originalname: '',
    pot_datecreated: new Date(),
    pot_houfk: 0,
    pot_porfk: 0,
    pot_client: 'SAM',
    pot_confk: 0,
    pot_createdby: 1,
    pot_createddate: new Date(),
    pot_updatedby: null,
    pot_updateddate: null
  }
  //#endregion

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
    private dialog: MatDialog,
    private loaderService: LoadingService,
    private toasterService: ToasterService,
    private poRequestAttachmentService: PORequestAttachmentService) {
    this.attachmentUploadForm = new FormGroup({
      fileUpload: new FormControl('')
    });
    this.poData = data.poRequest;
  }
  public onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }
  ngOnInit(): void {
    this.getAttachments();
  }
  getAttachments() {
    this.loaderService.show();
    if (this.poData.isWarrantyHouseOLD) {
      this.poRequestAttachmentService.getByHouse('housewold', this.poData.por_houfk).subscribe(result => {
        this.gridAttachmentData = result;
      });
    } else {
      this.poRequestAttachmentService.getByHouse('house', this.poData.por_houfk).subscribe(result => {
        this.gridAttachmentData = result;
      });
    }
    this.loaderService.hide(1);
  }
  closeDialog() {
    this.dialogRef.close({ ...this.poData });
  }
  onFileSelected(event: any) {
    this.poAttachment = {
      pot_pk: -1,
      pot_filedata: null,
      pot_originalname: '',
      pot_datecreated: new Date(),
      pot_houfk: 0,
      pot_porfk: 0,
      pot_client: 'SAM',
      pot_confk: 0,
      pot_createdby: 1,
      pot_createddate: new Date(),
      pot_updatedby: null,
      pot_updateddate: null
    }
    const file: File = event.target.files[0];
    const ext = file.name.split('.').pop();
    if (['txt', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', '.gif', 'bmp'].includes(ext!.toLowerCase())) {
      if (file) {
        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            const bytes = e.target.result.split('base64,')[1];
            this.poAttachment = {
              pot_pk: -1,
              pot_filedata: bytes,
              pot_originalname: file.name,
              pot_datecreated: new Date(),
              pot_houfk: this.poData.por_houfk,
              pot_porfk: this.poData.por_pk,
              pot_client: 'SAM',
              pot_confk: '1',
              pot_createdby: '1',
              pot_createddate: new Date(),
              pot_updatedby: null,
              pot_updateddate: null
            }
          };
        }, 500);
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        disableClose: true,
        data: { message: 'File extension not valid. Please select another file' }
      });
    }
  }
  uploadFile() {
    if (this.attachmentUploadForm.valid) {
      this.loaderService.show();
      this.poRequestAttachmentService.create(this.poAttachment, this.poData.isWarrantyHouseOLD).subscribe(result => {
        this.loaderService.hide(0);
        this.getAttachments();
        this.attachmentUploadForm.reset();
        this.toasterService.show('success', '', 'File uploaded successfully');
      }, error => {
        this.loaderService.hide(0);
        this.toasterService.show('error', '', 'File not uploaded, something is wrong');
      });
    }
  }
  downloadFile(dataRow: any) {
    const src = `data:image/jpg;base64,${dataRow.cat_filedata}`;
    const link = document.createElement("a");
    link.href = src;
    link.download = dataRow.cat_originalname;
    link.click();
  }
  removeFile(dataRow: any) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      data: { message: 'Are you sure you wish to delete file?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.loaderService.show();
        this.poRequestAttachmentService.delete(dataRow.pot_pk).subscribe(result => {
          this.loaderService.hide(0);
          this.getAttachments();
          this.toasterService.show('success', '', 'File deleted successfully');
        }, error => {
          this.loaderService.hide(0);
          this.toasterService.show('error', '', 'File not deleted, something is wrong');
        });
      }
    });
  }
}
