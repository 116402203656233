// Angular Import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// project import
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ChatUserListComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { SharedModule } from './theme/shared/shared.module';
import { SvgIconComponent, provideAngularSvgIcon } from 'angular-svg-icon';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  IgxButtonGroupModule,
  IgxButtonModule,
  IgxCardModule,
  IgxChipsModule,
  IgxComboModule,
  IgxExpansionPanelModule,
  IgxIconModule,
  IgxLayoutModule,
  IgxNavigationDrawerModule,
  IgxRippleModule,
  IgxSimpleComboModule,
  IgxToastModule,
  IgxToggleModule,
  IgxTreeModule,
  IgxInputGroupModule,
  IgxGridModule,
  IgxCheckboxModule,
  IgxDialogModule,
  IgxSelectModule,
  IgxDatePickerModule,
  IgxColumnComponent,
  IgxGridComponent,
  IgxTextSelectionModule,
  IgxTimePickerModule
} from 'igniteui-angular';
import { CoreModule } from './core/core.module';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { Globals } from 'src/globals';
import { AuthGuard } from './core/guards/auth.guard';
import { LoadingComponent } from './theme/shared/components/loader/loader.component';
import { AuthInterceptor } from './core/interseptors/auth.interceptor';
import { ToasterContainerComponent } from './toaster/toaster-container/toaster-container.component';
import ToasterComponent from './toaster/toaster.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonDropdownComponent } from './pages/dialogs/common-dropdown/common-dropdown.component';
import { SetPasswordComponent } from './pages/dialogs/set-password/set-password.component';
import { HouseOnHoldComponent } from './pages/dialogs/house-on-hold/house-on-hold.component';
import { TransferHousesComponent } from './pages/dialogs/transfer-houses/transfer-houses.component';
import { ConfirmationBoxComponent } from './pages/dialogs/confirmation-box/confirmation-box.component';
import { NgxColorsModule } from 'ngx-colors';
import { AlertDialogComponent } from './pages/dialogs/alert-dialog/alert-dialog.component';
import { PorequestComponent } from './pages/house/porequest/porequest.component';
import { ApplicationPipesModule } from './core/pipes/application-pipes-module';
import { IgxPreventDocumentScrollModule } from './core/directives/prevent-scroll.directive';
import { AttachmentDialogComponent } from './pages/dialogs/attachment-dialog/attachment-dialog.component';
import { AppointmentComponent } from './pages/dialogs/appointment/appointment.component';
import { PoRequestHistoryComponent } from './pages/tasks/po-request/po-request-history/po-request-history.component';
import { ReportViewerComponent } from './pages/dialogs/report-viewer/report-viewer.component';
import { ActiveReportsModule } from '@grapecity/activereports-angular';
import { BlogComponent } from './pages/tasks/blog/blog/blog.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  if (environment.production) {
    return new PublicClientApplication({
      auth: {
        clientId: '3ec6a1cd-d079-4123-bd33-d61fe62314e5',
        authority: 'https://devatsciem.ciamlogin.com/',
        redirectUri: 'https://dev-app.automatedtrackers.com/redirect',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  else {
    return new PublicClientApplication({
      auth: {
        clientId: '3ec6a1cd-d079-4123-bd33-d61fe62314e5',
        authority: 'https://devatsciem.ciamlogin.com/',
        redirectUri: 'https://dev-app.automatedtrackers.com/redirect',
        // redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.authConfig.scopes],
      state: window.location.hostname
    },
    // authRequest: environment.apiConfig,
    loginFailedRoute: '/login'
  };
}

// export function MSALInterceptorConfig(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes)
//   return {
//     interactionType: InteractionType.Popup,
//     protectedResourceMap
//   }
// }

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.authConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: {
//       scopes: [...environment.apiConfig.scopes]
//     },
//     loginFailedRoute: '/login-failed'
//   };
// }
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    LoadingComponent,
    AppComponent,
    AdminComponent,
    GuestComponent,
    ConfigurationComponent,
    NavBarComponent,
    NavigationComponent,
    NavLeftComponent,
    NavRightComponent,
    NavSearchComponent,
    ChatMsgComponent,
    ChatUserListComponent,
    FriendComponent,
    NavContentComponent,
    NavItemComponent,
    NavCollapseComponent,
    NavGroupComponent,
    AdminDashboardComponent,
    ToasterContainerComponent,
    ToasterComponent,
    PoRequestHistoryComponent,

    //#region Dialog Popup
    CommonDropdownComponent,
    SetPasswordComponent,
    HouseOnHoldComponent,
    TransferHousesComponent,
    ConfirmationBoxComponent,
    AlertDialogComponent,
    PorequestComponent,
    AttachmentDialogComponent,
    AppointmentComponent,
    ReportViewerComponent,
    BlogComponent
    //#endregion

  ], imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SvgIconComponent,
    IgxButtonModule,
    IgxIconModule,
    IgxLayoutModule,
    IgxNavigationDrawerModule,
    IgxRippleModule,
    IgxToggleModule,
    IgxTreeModule,
    IgxExpansionPanelModule,
    IgxChipsModule,
    IgxToastModule,
    MsalModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MsalModule,
    MatFormFieldModule,
    IgxComboModule,
    IgxSimpleComboModule,
    IgxCardModule,
    IgxInputGroupModule,
    IgxGridModule,
    NgxColorsModule,
    IgxCheckboxModule,
    IgxDialogModule,
    IgxSelectModule,
    IgxDatePickerModule,
    IgxColumnComponent,
    IgxPreventDocumentScrollModule,
    ApplicationPipesModule,
    IgxTextSelectionModule,
    IgxGridComponent,
    ActiveReportsModule,
    IgxTimePickerModule
  ],
  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FullCalendarModule,
    IgxComboModule,
    IgxSimpleComboModule,
    IgxButtonGroupModule,
    MatFormFieldModule,
    IgxCardModule,
    NgxColorsModule,
    IgxCheckboxModule,
    IgxDialogModule,
    IgxIconModule,
    MatIconModule,
    ActiveReportsModule,
    IgxTimePickerModule,

  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    DatePipe,
    MsalService,
    MsalGuard,
    AuthGuard,
    MsalBroadcastService,
    Globals,
    provideAngularSvgIcon()],
  bootstrap: [AppComponent]
})
export class AppModule { }
