import { Component, HostListener, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IColumnPipeArgs } from 'igniteui-angular';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { EmailCommunicationService } from 'src/app/core/services/email-communication.service';
import { LoadingService } from 'src/app/core/services/loader.service';
import { ReviewService } from 'src/app/core/services/review.service';
import { ToasterService } from 'src/app/toaster/toaster.service';
import DOMPurify from 'dompurify';

const PipeArgs: IColumnPipeArgs = {
  format: 'MM/dd/yyyy',
  timezone: 'UTC',
  digitsInfo: '1.1-2'
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
  @HostListener('window:resize', ['$event'])

  //#region Arrays and Objects
  windowWidth: any;
  public gridHouseOwnerCommentsData: any[] = [];
  public contact: any = {
    con_name: '',
    con_contact: '',
    con_email: ''
  };
  public user: any;
  type: any = 'ATS';
  house: any = '';
  sendDate: any = new Date();
  pipeArgs = PipeArgs;
  public commentForm: FormGroup;
  newReview: any = {
    rev_pk: -1,
    rev_confk: 0,
    rev_childfk: 0,
    rev_note: '',
    rev_isvendorread: false,
    rev_isbuilderread: false,
    rev_childcat: 0,
    rev_client: 0,
    rev_createdby: 1,
    rev_createddate: new Date(),
    rev_updatedby: null,
    rev_updateddate: null
  }
  //#endregion

  //#region Variables

  //#endregion

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<BlogComponent>,
    private reviewService: ReviewService,
    private contactService: ContactService,
    private emailService: EmailCommunicationService,
    private loaderService: LoadingService,
    private toasterService: ToasterService,
    private authenticationService: AuthenticationService) {
    this.commentForm = new FormGroup({
      comment: new FormControl('', [Validators.required])
    })
    if (data.contact !== null) {
      this.contact = data.contact;
      this.getReview();
    }
    else {
      this.getContact(data.con_pk);
    }

    this.type = data.type;
    this.house = data.house;
    this.sendDate = data.sendDate;
    this.loaderService.hide(0);
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.authenticationService.getUserInfo().then(value => {
      this.user = value;
    });
  }

  getContact(conPk: any) {
    this.loaderService.show();
    this.contactService.get(conPk).subscribe(res => {
      if (res && res.length > 0) {
        this.contact = res[0];
        this.getReview();
      }
      else {
        this.loaderService.hide(0);
        this.dialogRef.close();
      }
      this.loaderService.hide(1);
    }, error => {
      this.loaderService.hide(1);
    })
  }
  public onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }

  getReview() {
    this.newReview = {
      rev_pk: -1,
      rev_confk: 0,
      rev_childfk: 0,
      rev_note: '',
      rev_isvendorread: false,
      rev_isbuilderread: false,
      rev_childcat: 0,
      rev_client: 0,
      rev_createdby: 1,
      rev_createddate: new Date(),
      rev_updatedby: null,
      rev_updateddate: null
    }
    this.loaderService.show();
    this.reviewService.getBlog(this.contact.con_pk).subscribe(result => {
      this.gridHouseOwnerCommentsData = result.sort((a: any, b: any) => a.rev_createddate - b.rev_createddate);
      this.loaderService.hide(1);
    }, error => {
      this.loaderService.hide(1);
    });
  }
  close() {
    this.dialogRef.close();
  }
  postComment() {
    if (this.commentForm.valid) {
      this.newReview.rev_confk = this.contact.con_pk;
      this.newReview.rev_childfk = this.user.usr_pk;
      this.newReview.rev_isvendorread = false;
      this.newReview.rev_isbuilderread = true;
      this.newReview.rev_childcat = this.type;
      this.newReview.rev_client = 'SAM';
      this.loaderService.hide(0);
      this.loaderService.show();
      this.reviewService.create(this.newReview).subscribe(result => {
        this.toasterService.show('success', '', 'Comment send successfully');
        this.sendEmail(this.newReview.rev_note);
        this.getReview();
        this.loaderService.hide(1);
      }, error => {
        this.loaderService.hide(1);
      });
      this.close();
    }
  }
  sendEmail(comment: string) {
    let subject = this.user.usr_name + ' has posted new comment'
    let body = '<center><font color=red size=4>*** Note ***</font></center><br/><br/>'
    body = body + '<center><font color=red size=4>Please reply back to builder\'s email address for further communication : ' + this.user.usr_email + '</font></center><br/><br/>'

    if (this.type === 'HouseActivity') {
      body = body + this.user.usr_name + ' has posted new comment " ' + comment + ' " in VIC for house ' + this.house + ' on Activity scheduled on ' + this.sendDate + '. <br/><br/> Please click here to go to the VIC website. <br/> <font color =blue >www.AutomatedTrackers.net/VIC</font> <br/><br/><br/> Automated Trackers <br/> 713.344.0737 <br/> <font color=blue>www.AutomatedTrackers.com</font>'
    }
    else if (this.type === 'ATS') {
      body = body + this.user.usr_name + ' has posted new comment in VIC.' + ' <br/><br/> Please click here to go to the VIC website. <br/> <font color =blue >www.AutomatedTrackers.net/VIC</font> <br/><br/><br/> Automated Trackers <br/> 713.344.0737 <br/> <font color=blue>www.AutomatedTrackers.com</font>'
    }
    else if (this.type === 'WarrantyLetters') {
      body = body + this.user.usr_name + ' has posted new comment in VIC for house ' + this.house + ' on Warranty Letters Punch List sent on ' + this.sendDate + '. <br/><br/> Please click here to go to the VIC website. <br/> <font color =blue >www.AutomatedTrackers.net/VIC</font> <br/><br/><br/> Automated Trackers <br/> 713.344.0737 <br/> <font color=blue>www.AutomatedTrackers.com</font>'
    }
    else if (this.type === 'Note') {
      body = body + this.user.usr_name + ' has posted new comment in VIC for house ' + this.house + ' on Punch List sent on ' + this.sendDate + '. <br/><br/> Please click here to go to the VIC website. <br/> <font color =blue >www.AutomatedTrackers.net/VIC</font> <br/><br/><br/> Automated Trackers <br/> 713.344.0737 <br/> <font color=blue>www.AutomatedTrackers.com</font>'
    }
    let emailpayload = {
      FromAddress: this.user.usr_email,
      ToAddress: this.contact.con_email,
      CCAddress: '',
      BCCAddress: '',
      Subject: subject,
      body: this.strToUtf8Bytes(body),
      priority: 1,
      attachments: null
    };
    this.emailService.sendEmail(emailpayload).subscribe(res => {
    }, (error: any) => {

    });
  }
  strToUtf8Bytes(str: any) {
    const utf8 = [];
    for (let ii = 0; ii < str.length; ii++) {
      let charCode = str.charCodeAt(ii);
      if (charCode < 0x80) utf8.push(charCode);
      else if (charCode < 0x800) {
        utf8.push(0xc0 | (charCode >> 6), 0x80 | (charCode & 0x3f));
      } else if (charCode < 0xd800 || charCode >= 0xe000) {
        utf8.push(0xe0 | (charCode >> 12), 0x80 | ((charCode >> 6) & 0x3f), 0x80 | (charCode & 0x3f));
      } else {
        ii++;
        // Surrogate pair:
        // UTF-16 encodes 0x10000-0x10FFFF by subtracting 0x10000 and
        // splitting the 20 bits of 0x0-0xFFFFF into two halves
        charCode = 0x10000 + (((charCode & 0x3ff) << 10) | (str.charCodeAt(ii) & 0x3ff));
        utf8.push(
          0xf0 | (charCode >> 18),
          0x80 | ((charCode >> 12) & 0x3f),
          0x80 | ((charCode >> 6) & 0x3f),
          0x80 | (charCode & 0x3f),
        );
      }
    }
    return utf8;
  }
  sanitizeHtml(html: string): string {
    // Sanitize the HTML
    const sanitized = DOMPurify.sanitize(html);
    // Encode the sanitized HTML
    return encodeURIComponent(sanitized);
  }
}

