import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyApprovalLevelService extends ApiService<any> {

  constructor(protected http: HttpClient) {
    super('company-approval-level');
  }
  get(companyId: any): Observable<any> {
    return this.http.get(`${this.path}/${companyId}`);
  }

  getByUser(companyId: any): Observable<any> {
    return this.http.get(`${this.path}?action=user&company=${companyId}`);
  }

  bulkInsert(model: any[]): Observable<any> {
    return this.http.post<any>(`${this.path}?action=bulk`, model);
  }

  bulkUpdate(id: number, model: any[]): Observable<any> {
    return this.http.put<any>(`${this.path}/${id}?action=bulk`, model);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.path}/${id}`)
  }
}
