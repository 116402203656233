<igx-card-header class="dialog-header" style="position: fixed;width: 80%;z-index: 999;">
    {{title}}
</igx-card-header>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="blog-form" (resize)="onResize($event)" class="pt-5" style="height: calc(100% - 10px);overflow: auto;">
    <div style="box-shadow:none;">
        <div id="viewer-host">
            <gc-activereports-viewer [fullscreen]="false">
            </gc-activereports-viewer>
        </div>
    </div>
</div>