import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class PORequestAttachmentService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('po-request-attachment');
    }

    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByPORequest(pk: any): Observable<any> {
        return this.http.get(`${this.path}?action=porequest&porequest=${pk}`);
    }
    create(data: any, isWold: boolean = false): Observable<any> {
        if (isWold) {
            return this.http.post(`${this.path}/UploadWoldAttachment`, data);
        } else {
            return this.http.post(`${this.path}`, data);
        }
    }
    delete(pk: any): Observable<any> {
        return this.http.delete(`${this.path}/${pk}`);
    }
    getByHouse(action: any, house: number): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&house=${house}`);
    }
}