import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Core } from "@mescius/activereportsjs";

if (!environment.isLocal) {
  Core.setLicenseKey("*.automatedtrackers.com,699319624175459#B14ZNcZx6QDhXO0d5dTdVQ8NkQqREcMhXc4ATVBJFTBhkdtVUWrJjQvpVU4cXerJkRzMDWrcURFdzS9pHMOtma6gVU9MTT7d4cVhXR6g7VONlWJZmcq9mTvsUbUV5R5Y6dEFnZDRmVPFDc5ATb9MXZ8MUTx86Y7QlQIFlMXNXMmJmWId6arRlTOJWYlZ6SslHT5BjakBXbrwWZSZldillWSJ5KrQTMqRzN6EzRR9Ed52iWwx4QmlUWFhGVTFEaydnVudjVMBXN5F7KYJ7YrATQCVXQEhmStl7aaFXcxQFa8Mjb53yMMNkYvlET9NUMT5EdwNjYXhWTTJUViojITJCLiYEM8QTMERUMiojIIJCL4gTMxAjM6UTN0IicfJye=#Qf35VfiYFTL9kI0IyQiwiI5YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiMwMDM6ADI9ETMxQjMwIjI0ICdyNkIsISbvNmLzJXZrNWYyRHZlRXYt3Gd5FmLqIiOiMXbEJCLiMnclt6YhJHVgQWZ4FWbvRXdBJiOiEmTDJCLikTN4UzNxQjM6kTMzkTO6IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBdzVPlVU58GVwYFNzhEaNZXW92GMSBTSQZnUxgFT9VGSstiSU3yN9x6MzoVQvFkSkZXROB7N9NDUkVUeppHb4RGWTBnRsFzLTRWVh3kevgkVFNFRmt4YypkahBmW");
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
