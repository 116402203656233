import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/services/loader.service';
import { PORequestHistoryService } from 'src/app/core/services/porequest-history-.service';

@Component({
  selector: 'app-po-request-history',
  templateUrl: './po-request-history.component.html',
  styleUrls: ['./po-request-history.component.scss']
})
export class PoRequestHistoryComponent {

  //#region Objects and Arrays
  public gridHistoryData: any[] = [];
  public innerWidth: any;
  //#endregion

  //#region Variables
  private por_pk: number = 0;
  private bkc_pk: number | null = null;
  public poNumber: string = '';
  //#endregion

  constructor(private poRequestHistory: PORequestHistoryService,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PoRequestHistoryComponent>) {
    this.por_pk = data.rowData.por_pk;
    this.bkc_pk = data.rowData.bkc_pk;
    this.poNumber = data.rowData.por_po

    this.getPORequestHistory();
  }
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  getPORequestHistory() {
    this.loader.show();
    this.poRequestHistory.getByPORequest(this.por_pk, this.bkc_pk, false).subscribe(result => {
      this.gridHistoryData = result.sort((a: any, b: any) => a.poh_rejectdate - b.poh_rejectdate);
      this.loader.hide();
    }, error => {
      this.loader.hide();
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
